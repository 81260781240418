<template>
  <v-container grid-list-md fluid>
    <v-tabs show-arrows grow v-model="activeCartTab">
      <v-tab v-for="shopObj in shops" :key="shopObj.id">{{
        shopObj.title
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeCartTab">
      <v-tab-item v-for="shop in getShopsInCart.length" :key="shop">
        <v-layout row wrap>
          <v-flex
            xs12
            :key="index"
            v-for="(item, index) in getItemsInCartByShop(activeShop)"
          >
            <v-card>
              <v-card-text>
                <v-img
                  contain
                  max-width="500px"
                  max-height="180px"
                  :src="
                    $config.managerMedia + 'picturepool/' + item.shop_item_image
                  "
                  :lazy-src="
                    $config.managerMedia + 'picturepool/' + item.shop_item_image
                  "
                ></v-img>
              </v-card-text>
              <v-card-text>
                <v-select
                  outlined
                  :label="$t('modal.quantitySelection.title')"
                  filled
                  hide-details
                  item-text="quantity"
                  :value="item.quantity"
                  @input="
                    (val) => {
                      modifyCartItemQuantity({
                        cartId: item.cartId,
                        quantity: parseInt(val),
                      });
                    }
                  "
                  :items="quantitys"
                ></v-select>
              </v-card-text>
              <v-card-title class="pb-0">{{
                item.shop_item_title
              }}</v-card-title>
              <v-card-title class="pt-0">
                <div class="title">
                  {{
                    item.shop_item_price
                      | currency($store.state.session.currencyConfig)
                  }}
                </div>
              </v-card-title>
              <v-card-text>
                <div
                  v-if="
                    item.shop_item_description &&
                    item.shop_item_description.length > 0
                  "
                  class="text-caption pb-2"
                >
                  {{ item.shop_item_description }}
                </div>
                <div
                  v-for="(addItem, index) in item.purchase_flow_selected"
                  :key="index"
                >
                  <span class="text-caption">
                    + {{ addItem.sub_item_title }}
                    {{
                      addItem.sub_item_price
                        | currency($store.state.session.currencyConfig)
                    }}
                  </span>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  text
                  block
                  color="primary"
                  @click="
                    modifyCartItemQuantity({
                      cartId: item.cartId,
                      quantity: 0,
                    }),
                      handleDeletedItem()
                  "
                  >{{ $t("shop.removeFromCart") }}</v-btn
                >
              </v-card-actions>
              <v-divider></v-divider>
            </v-card>
          </v-flex>

          <v-flex xs12>
            <div class="subtitle py-3">
              {{ $t("shop.shopCartDeliveryText") }}
            </div>
            <v-card flat>
              <v-card-text>
                <template v-if="info.optional_guest_delivery_time">
                  <v-dialog
                    ref="dialog"
                    :return-value.sync="deliveryDate[activeCartTab]"
                    persistent
                    lazy
                    full-width
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="deliveryDate[activeCartTab]"
                        :label="$t('shop.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="deliveryDate[activeCartTab]"
                      scrollable
                      :first-day-of-week="1"
                      :min="new Date().toISOString().substr(0, 10)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateModal = false">{{
                        $t("shop.purchaseFlowBack")
                      }}</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog[shop - 1].save(
                            deliveryDate[activeCartTab]
                          )
                        "
                        >{{ $t("modal.ok") }}</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                  <v-dialog
                    ref="timeDialog"
                    :return-value.sync="deliveryTime[activeCartTab]"
                    persistent
                    lazy
                    full-width
                    width="290px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="deliveryTime[activeCartTab]"
                        :label="$t('shop.time')"
                        prepend-icon="mdi-alarm"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      format="24hr"
                      v-model="deliveryTime[activeCartTab]"
                      color="primary"
                      :min="
                        $helpers.formatDate(
                          new Date(
                            new Date().getTime() + shopDeliveryTime * 60000
                          ),
                          '%H:%M'
                        )
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="timeModal = false">{{
                        $t("shop.purchaseFlowBack")
                      }}</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.timeDialog[shop - 1].save(
                            deliveryTime[activeCartTab]
                          )
                        "
                        >{{ $t("modal.ok") }}</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </template>
                <v-text-field
                  ref="location"
                  :label="$t('shop.location')"
                  prepend-icon="mdi-map-marker-outline"
                  v-model="location"
                  @blur="locationBlurHandler"
                  :clearable="location !== userRoomNumber"
                >
                  <template v-slot:append-outer>
                    <v-btn icon @click="openQrCodeReader">
                      <v-icon>mdi-qrcode-scan</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 v-if="$config.shop_additional_message_enabled">
            <v-textarea
              :label="$t('shop.additionalMessage')"
              v-model="additionalMessage[activeCartTab]"
            >
            </v-textarea>
          </v-flex>

          <v-flex xs12>
            <v-card>
              <v-card-title class="text-subtitle-2 pb-2">{{
                $t("shop.total")
              }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="title">{{
                parseAndSumList(getItemsInCartByShop(activeShop))
                  | currency($store.state.session.currencyConfig)
              }}</v-card-text>

              <v-card-text v-if="statusTransmit === 'failed'">
                <v-alert type="error">
                  {{ $t("shop.placeOrderFailed") }}
                </v-alert>
              </v-card-text>

              <v-card-text
                v-if="
                  !$store.state.session.authResult.checkin ||
                  !$store.state.session.authResult.checkin[0] ||
                  !$store.state.session.authResult.checkin[0].name
                "
              >
                <v-alert type="info">
                  {{ $t("shop.noLogin") }}
                </v-alert>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :disabled="
                    !$store.state.session.authResult.checkin ||
                    !$store.state.session.authResult.checkin[0] ||
                    !$store.state.session.authResult.checkin[0].name
                  "
                  @click="placeOrder()"
                  :loading="statusTransmit === 'sending'"
                  block
                  color="primary"
                >
                  {{ $t("shop.placeOrder") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog style="max-width: 400px" v-model="successSendOrderMessage">
      <v-card>
        <v-card-title class="title">{{
          $t("shop.successSendOrder")
        }}</v-card-title>
        <v-card-text v-if="info.optional_guest_delivery_time">{{
          $t("shop.guestDeliveryTimeSelected", {
            date: deliveryDate[activeCartTab],
            time: deliveryTime[activeCartTab],
          })
        }}</v-card-text>
        <v-card-text v-else>{{
          $t("shop.deliveredAsSoonAsPossible")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="successSendOrderMessage = false"
            text
            color="primary"
            >{{ $t("modal.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      group: undefined,
      quantity: 1,
      dateModal: false,
      timeModal: false,
      deliveryDate: [],
      deliveryTime: [],
      successSendOrderMessage: false,
      additionalMessage: [],
      location: "",
      activeCartTab: 0,
    };
  },
  mounted() {
    /* When browser is refreshed, load the shoptitles (and removedShopId) again
    into the vuex store to be able to display them as tabs */
    if (isEmpty(this.shops) && !isEmpty(this.cartItemList)) {
      this.$store.commit(
        "shop/SET_SHOPS",
        JSON.parse(localStorage.getItem("shop-cart-title-list"))
      );
    }

    if (!localStorage.getItem("shop-cart-removed-item-shop-id")) {
      this.$store.commit("SAVE_REMOVED_ITEM_SHOP_ID", -1);
      localStorage.setItem("shop-cart-removed-item-shop-id", -1);
    } else {
      this.$store.commit(
        "shop/SAVE_REMOVED_ITEM_SHOP_ID",
        JSON.parse(localStorage.getItem("shop-cart-removed-item-shop-id"))
      );
    }

    this.location = this.otherLocation
      ? this.otherLocation
      : this.userRoomNumber;
  },
  methods: {
    ...mapActions("shop", [
      "getInfo",
      "getContent",
      "transmitOrder",
      "modifyCartItemQuantity",
      "resetCartList",
    ]),
    ...mapActions(["openQrCodeReader"]),
    initData() {
      this.$nextTick(() => {
        this.deliveryTime[this.activeCartTab] = this.$helpers.formatDate(
          new Date(new Date().getTime() + (this.shopDeliveryTime || 0) * 60000),
          "%H:%M"
        );
      });
      this.getInfo(this.$route.params.id || this.cartItemList[0].shopId);
      this.getContent(this.$route.params.id);
    },
    handleDeletedItem() {
      this.$store.commit("shop/SAVE_REMOVED_ITEM_SHOP_ID", this.activeShop);

      var helper = [];

      this.cartItemList.forEach((item) => {
        if (!helper.includes(item.shopId)) {
          helper.push(item.shopId);
        }
      });

      var flag = false;

      helper.forEach((ele) => {
        if (this.removedItemShopId === ele) {
          flag = true;
        }
      });

      if (!flag) {
        this.$store.commit("shop/REMOVE_FROM_SHOPS", this.removedItemShopId);
        this.$store.commit("shop/SAVE_REMOVED_ITEM_SHOP_ID", -1);
      }
    },
    updateShop(val) {
      this.activeShop = this.getShopsInCart[val];

      /* Update current shopId  */
      this.getInfo(this.activeShop);
    },
    getItemsInCartByShop(shopNr) {
      return this.cartItemList.filter((item) => item.shopId === shopNr);
    },
    setOtherLocation(val) {
      if (val) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { ...this.$router.currentRoute.query, location: val },
        });
      } else {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { ...this.$router.currentRoute.query, location: undefined },
        });
      }
    },
    parseAndSum: function (a, b) {
      const aParsed = parseFloat(a);
      const bParsed = parseFloat(b);
      if (isFinite(aParsed) && isFinite(bParsed)) {
        return aParsed + bParsed;
      }
      return "";
    },
    parseAndSumList: function (list) {
      const _this = this;
      var sum = 0;
      each(list, function (element) {
        const parsedPrice = parseFloat(element.shop_item_price);
        const parsedQuantity = parseFloat(element.quantity);
        var subItemSum = 0;
        each(element.purchase_flow_selected, function (subItem) {
          subItemSum +=
            _this.parseAndSum(
              subItem.sub_item_price,
              subItem.sub_item_price_diff
            ) * parsedQuantity;
        });
        if (isFinite(parsedPrice) && isFinite(parsedQuantity)) {
          sum += parsedPrice * parsedQuantity + subItemSum;
        }
      });
      return sum;
    },
    async placeOrder() {
      if (this.statusTransmit === "sending") {
        return;
      }

      await this.getInfo(this.$route.params.id || this.cartItemList[0].shopId);
      // check again if shop is still open
      // else info mutation will redirect to home automatically
      if (this.$store.state.shop.info.isOpen) {
        this.$store.commit("shop/SAVE_REMOVED_ITEM_SHOP_ID", this.activeShop);
        this.transmitOrder({
          cartItemList: this.getItemsInCartByShop(this.activeShop),
          retailId: this.cartItemList[0].shopId,
          additionalMessage: this.additionalMessage[this.activeCartTab],
          deliveryTime: new DateTime.fromFormat(
            this.deliveryDate[this.activeCartTab] +
              " " +
              this.deliveryTime[this.activeCartTab] +
              ":00",
            "yyyy-MM-dd HH:mm:ss"
          ).toJSDate(),
        });
      } else {
        this.showShopClosedMessage = true;
      }
    },
    locationBlurHandler() {
      if (!this.location) {
        this.setOtherLocation("");
        this.location = this.userRoomNumber;
      } else {
        this.setOtherLocation(this.location);
      }
    },
  },
  computed: {
    ...mapState("shop", {
      statusTransmit: (state) => state.statusTransmit,
      statusContent: (state) => state.statusContent,
      content: (state) => state.content,
      activeGroup: (state) => state.activeGroup,
      parentGroup: (state) => state.parentGroup,
      rootTemplateData: (state) => state.rootTemplateData,
      info: (state) => state.info,
      cartItemList: (state) => state.cartItemList,
      shops: (state) => state.shops,
      removedItemShopId: (state) => state.removedItemShopId,
    }),
    ...mapState("session", {
      loginDataRoom: (state) => state.loginData.room,
    }),
    showShopClosedMessage: {
      get() {
        return this.$store.state.shop.showShopClosedMessage;
      },
      set(val) {
        return this.$store.commit("shop/SET_SHOW_SHOP_CLOSED_MESSAGE", val);
      },
    },
    getShopsInCart() {
      var helper = [];

      this.cartItemList.forEach((item) => {
        if (!helper.includes(item.shopId)) {
          helper.push(item.shopId);
        }
      });

      return helper;
    },
    otherLocation() {
      return this.$route.query.location;
    },
    userRoomNumber() {
      if (this.loginDataRoom) {
        return this.$t("login.room") + " " + this.loginDataRoom;
      }
      return "";
    },
    quantitys() {
      var q = [];
      for (var i = 1; i < 101; i++) {
        q.push({ quantity: i });
      }
      return q;
    },
    shopDeliveryTime() {
      return parseInt(this.info.delivery_time);
    },
  },
  created() {
    for (var x = 0; x < this.getShopsInCart.length; x++) {
      this.deliveryDate[x] = new Date().toISOString().substr(0, 10);
      this.deliveryTime[x] = new Date().toISOString().substr(11, 5);
    }
    this.activeShop = this.cartItemList[0].shopId;
    this.initData();
  },
  watch: {
    "$store.state.shop.showShopClosedMessage"() {
      this.updateShop(this.activeCartTab);
    },
    "$store.state.shop.shops"() {
      this.updateShop(this.activeCartTab);
    },
    activeCartTab(val) {
      this.updateShop(val);

      var helper = [];

      this.cartItemList.forEach((item) => {
        if (!helper.includes(item.shopId)) {
          helper.push(item.shopId);
        }
      });

      var flag = false;

      helper.forEach((ele) => {
        if (this.removedItemShopId === ele) {
          flag = true;
        }
      });

      if (!flag && this.removedItemShopId !== -1) {
        this.$store.commit("shop/REMOVE_FROM_SHOPS", this.removedItemShopId);
        this.$store.commit("shop/SAVE_REMOVED_ITEM_SHOP_ID", -1);
      }
    },
    "$route.params.id"(val) {
      this.initData();
    },
    cartItemList(val) {
      if (val.length < 1) {
        this.$router.push({ name: "home" });
      }
    },
    statusTransmit(val) {
      if (val === "success") {
        this.successSendOrderMessage = true;
      }
    },
    successSendOrderMessage(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.resetCartList({
          cartItemList: this.getItemsInCartByShop(this.activeShop),
        });
        this.$store.commit("shop/REMOVE_FROM_SHOPS", this.removedItemShopId);
        this.$store.commit("shop/SAVE_REMOVED_ITEM_SHOP_ID", -1);
        this.updateShop(this.activeCartTab);
      }
    },
    shopDeliveryTime(val) {
      if (val > 0) {
        this.deliveryTime[this.activeCartTab] = this.$helpers.formatDate(
          new Date(new Date().getTime() + val * 60000),
          "%H:%M"
        );
      }
    },
    otherLocation(val) {
      this.location = val ? val : this.userRoomNumber;
    },
  },
};
</script>
